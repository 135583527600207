<template>
  <v-flex xs12 md3>
    <v-card
      class="expande-horizontal coluna"
      style="border-radius: 6px; display: flex; width: 100%;"
    >
      <v-img
        class="white--text align-end elevation-2"
        height="200px"
        style="border-radius: 6px"
        :src="ativoDeInvestimento.backgroundImage"
      >
        <v-card-title
          style="height: 200px; background: linear-gradient(360deg, #2196F3, rgba(255,255,255,0.2)); align-items: center; justify-content: center; flex-direction: column"
        >
          <v-avatar
            size="63"
            style="z-index: 3000; position: relative;"
            color="white"
          >
            <v-img color="yellow darken-3" :src="item.icone" size="36" />
          </v-avatar>
          {{ ativoDeInvestimento.title }}
          <span style="font-size: 10pt">
            {{ ativoDeInvestimento.description }}
          </span>
        </v-card-title>
      </v-img>

      <v-card-text class="text--primary">
        <div class="pa-2 expande-horizontal semquebra">
          <div class="centraliza">
            <h3 class="white--text">Aporte</h3>
            <h2
              class="font-weight-bold fonteCash green--text"
              style="font-size: 18pt"
            >
              R$ {{ ativoDeInvestimento.price }}
            </h2>
          </div>

          <v-spacer></v-spacer>
        </div>

        <div class="mt-6">
          <v-list dense nav class="pa-0 ma-0">
            <v-list-item>
              <v-icon size="18" class="mr-2">check</v-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Porcentagem total a pagar
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-actions>
                {{ ativoDeInvestimento.totalGain }}%
              </v-list-item-actions>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item>
              <v-icon size="18" class="mr-2">add_shopping_cart</v-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  Disponibilidade
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-actions>
                <span
                  :class="
                    ativoDeInvestimento.available ? 'green--text' : 'red--text'
                  "
                >
                  {{
                    ativoDeInvestimento.available
                      ? "Disponível"
                      : "Indisponível"
                  }}
                </span>
              </v-list-item-actions>
            </v-list-item>

            <v-divider></v-divider>
          </v-list>
        </div>
      </v-card-text>

      <v-card-actions>
        <div class="expande-horizontal centraliza mt-6 mb-6">
          <v-btn
            @click="
              createTransaction({
                type: 'investment',
                item: ativoDeInvestimento
              })
            "
            :disabled="!ativoDeInvestimento.available"
            rounded
            :color="$theme.primary"
            class="white--text"
          >
            Comprar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["ativoDeInvestimento"],
  computed: {
    ...mapGetters(["getTransaction"])
  },
  methods: {
    ...mapActions(["createTransaction"])
  }
};
</script>
