<template>
  <div class="expande-horizontal pa-3 wrap">
    <div
      class="expande-horizontal mb-3 mt-3"
    >
      <span style="font-size: 18pt;" class="fonte white--text mr-2">
        Endereços
      </span>
      <v-btn @click="openModalViewAddress(false)" class="white--text fonte" small :color="$theme.primary"> Novo Endereço + </v-btn>
    </div>
    <v-list color="transparent" class="expande-horizontal column" three-line>
      <template v-for="(item,i) in getAddresses">
        <v-list-item class="list-item" @click="openModalViewAddress(item)" :key="item._id">
          <v-list-item-content>
            <v-list-item-title class="fonte white--text"> {{ item.address }} </v-list-item-title>
            <v-list-item-subtitle class="fonte white--text"> Bairro: {{ item.neigthborhood }} </v-list-item-subtitle>
            <v-list-item-subtitle class="fonte orange--text">número: {{ item.house_number }} </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="i"></v-divider>
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from './components/Form'

export default {
  components: {
    Form
  },
  computed: {
    ...mapGetters(["getAddresses"])
  },
  methods: {
    ...mapActions([
      "openModalViewAddress",
      "listAddresses"
    ])
  },
  created() {
    this.listAddresses()
  }
};
</script>

<style>
  .list-item {
    border: 1px solid #666;
    width: 100%;
    border-radius: 6px;
    background: #444;
    margin-bottom: 6px;
  }
</style>
